import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Router, RouterModule } from '@angular/router'
import { NotificationService } from '../../notification/notification.service'
import { LogoutComponent } from '../../logout/logout.component'

@Component({
  selector: 'app-application-selector',
  standalone: true,
  imports: [CommonModule, RouterModule, LogoutComponent],
  templateUrl: './application-selector.component.html',
  styleUrls: ['./application-selector.component.scss']
})
export class ApplicationSelectorComponent {

  constructor(
    private notificationService: NotificationService,
    private router: Router,
  ) { }


  userAuthData = ""

  ngOnInit(): void {
    this.userAuthData = sessionStorage.getItem("user-auth") ?? ""
  }

  appUris = {
    non: "",
    erp: 'http://localhost:4200/portal',
    vms: 'http://localhost:4200/'
  }


  directToAppUrl(link: string) {
    window.location.href = link
  }

  async handleAppredirect<T extends keyof typeof this.appUris>(appName: T) {
    let authData = JSON.parse(this.userAuthData)

    let accessToken = authData.accessToken
    let refreshToken = authData.refreshToken
    let appUri = this.appUris[appName]

    if (this.userAuthData === "") {
      this.notificationService.Error("System can't Identify you as a valid user", "Sorry! ")
    }
    else {
      // let tokenHealth = await this.testTokenHealth()
      let tokenHealth = null

      if (tokenHealth) {
        let redirectLink = `${appUri}?maxim_portal=${accessToken}+${refreshToken}`
        this.directToAppUrl(redirectLink)
      }
      else {
        let redirectLink = `${appUri}`
        this.notificationService.AppDirectMessage(this.directToAppUrl, redirectLink, "Your session has expired. Would you like to go to the app?", "Session Time out!", "Yes", "No")
      }

    }
  }

  // testTokenHealth(): Promise<boolean> {
  //   return new Promise<boolean>((resolve, reject) => {
  //     this.authAPIService.testTokens().subscribe(
  //       (response) => {
  //         const health = response.data === "ok"
  //         resolve(health)
  //       },
  //       (error) => {
  //         console.error(error)
  //         resolve(false)
  //       }
  //     )
  //   })
  // }

}
