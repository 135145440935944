import { Component, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faAt, faFingerprint, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../services';
import { UserCredential } from '../../types/userAuth.types';
import {GoogleAuthProvider,} from "firebase/auth";

import firebase from 'firebase/compat/app';
import OAuthProvider = firebase.auth.OAuthProvider;

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
  ],
  templateUrl: './login.component.html',
})
export class LoginComponent {
  username = signal('');
  password = signal('');

  protected readonly faAt = faAt;
  protected readonly faFingerprint = faFingerprint;
  protected readonly faEye = faEye;
  protected readonly faEyeSlash = faEyeSlash;

  showPassword = signal(false);
  touchedFields = signal<Set<string>>(new Set());

  protected readonly greeting = computed(() => {
    const savedUsername = this.authService.getUserName();
    return savedUsername ? `Welcome back, ${savedUsername}!` : 'Hello There!';
  });

  protected readonly greetingMessage = computed(() => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) return 'Good Morning!';
    if (currentHour < 18) return 'Good Afternoon!';
    return 'Good Evening!';
  });

  constructor(public authService: AuthService) {}

  onUserLogin(): void {
    this.touchedFields.update(fields => {
      fields.add('username');
      fields.add('password');
      return new Set(fields);
    });

    if (!this.isFormValid()) {
      this.authService.handleLoginFailure('Please fill in all required fields.');
      return;
    }

    const credentials: UserCredential = {
      username: this.username(),
      password: this.password()
    };

    this.authService.signIn(credentials);
  }

  async handleGoogleSignIn(): Promise<void> {
    return this.authService.handleProviderSignIn(new GoogleAuthProvider());
  }

  async handleMicrosoftSignIn(): Promise<void> {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    return this.authService.handleProviderSignIn(provider);
  }

  private isFormValid(): boolean {
    return this.username().trim() !== '' && this.password().trim() !== '';
  }

  onFieldBlur(fieldName: string): void {
    this.touchedFields.update(fields => {
      fields.add(fieldName);
      return new Set(fields);
    });
  }

  isFieldInvalid(fieldName: 'username' | 'password'): boolean {
    return this.touchedFields().has(fieldName) && this[fieldName]().trim() === '';
  }

  inputClass(fieldName: 'username' | 'password'): string {
    return this.isFieldInvalid(fieldName)
      ? 'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-xl focus:ring-red-500 focus:border-red-500 block w-full ps-10 p-2.5'
      : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5';
  }

  togglePasswordVisibility(): void {
    this.showPassword.update(value => !value);
  }
}
