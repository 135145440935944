<div class="containerw">

    <!-- <div class="centered-div">
        deded
    </div> -->

    <div class="welcome-container">

        <div class="slider">
            <div class="logo">
                <img src="../.././../../../assets/maxim-logo.webp" alt="maxim">
            </div>
            <div class="title">
                <h1>Welcome to Maxim Impressions AppPortal.</h1>
            </div>
        </div>

    </div>

    <!-- <div class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
        A
      </div>

      <div class="h-10 w-15 bg-red-600 animate-[wiggle_1s_ease-in-out_infinite]">

      </div> -->


    <div class="tile-container">

        <!-- <div class="tile">
            <a [routerLink]="['/price-approval']">
                <div class="box">
                    <div class="app-img">
                        <img src="assets/maxim-apps/images/ERP.png" alt="ERP">
                    </div>
                    <div class="app-name">MAXIM ERP</div>
                    <div class="app-short-name">ERP</div>
                </div>
            </a>
        </div> -->

        <div class="tile">
            <a (click)="handleAppredirect('erp')">
            <!-- <a [href]="vmsLink"> -->
                <div class="box">
                    <div class="app-img">
                        <img src="assets/maxim-apps/images/ERP.png" alt="ERP">
                    </div>
                    <div class="app-name">MAXIM ERP</div>
                    <div class="app-short-name">ERP</div>
                </div>
            </a>
        </div>

        <div class="tile">
            <a (click)="handleAppredirect('vms')">
            <!-- <a [href]="vmsLink"> -->
            <div class="box">
                <div class="app-img">
                    <img src="assets/maxim-apps/images/Role.png" alt="VMS">
                </div>
                <div class="app-name">Visitor Management System</div>
                <div class="app-short-name">VMS</div>
            </div>
            </a>
        </div>

        <div class="tile">
            <div class="box">
                <div class="app-img">
                    <img src="assets/maxim-apps/images/Asset.png" alt="ATS">
                </div>
                <div class="app-name">Asset Tracking System</div>
                <div class="app-short-name">ATS</div>
            </div>
        </div>

        <div class="tile">
            <div class="box">
                <div class="app-img">
                    <img src="assets/maxim-apps/images/Tenant.png" alt="HR">
                </div>
                <div class="app-name">HR Information</div>
                <div class="app-short-name">HR</div>
            </div>
        </div>

    </div>

    <!-- <div class="description">
        <h3>
            "Discover, access, and explore your apps with a single click.
            Your digital journey starts here."
        </h3>
    </div> -->

    <div class="settings-bar">
        <a [routerLink]="['/settings']">
            <div class="setting-img">
                <img src="assets/maxim-apps/images/Settings.png" alt="settings">
            </div>
        </a>
    </div>

    <div class="footer">
        <h4> Powered by : Maxim IT - Sri Lanka</h4>
    </div>

    <app-logout></app-logout>


</div>