<div class="flex flex-col md:flex-row h-screen">
  <div
    class="w-full md:w-3/5 bg-gradient-to-b from-blue-600 to-blue-900 flex flex-col justify-center items-center min-h-screen md:min-h-0 p-8 md:p-12 relative overflow-hidden">
    <div class="text-center z-10 mb-8 md:mb-0">
      <h1 class="text-white text-3xl md:text-4xl font-bold mb-4">Maxim Impressions</h1>
      <p class="text-white">Welcome to Maxim App Portal</p>
    </div>

    <div class="md:hidden bg-white rounded-lg p-6 shadow-lg w-full max-w-md z-10">
      <p class="text-gray-600 mb-6 text-center">{{ greeting() }}</p>
      <form (ngSubmit)="onUserLogin()" class="space-y-4">
        <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
            <fa-icon [icon]="faAt" [ngClass]="isFieldInvalid('username') ? 'text-red-400' : 'text-gray-400'"></fa-icon>
          </div>
          <input type="text" id="username-mobile"
                 [(ngModel)]="username" name="username-mobile"
                 (blur)="onFieldBlur('username')"
                 [ngClass]="inputClass('username')"
                 class="w-full pl-10 pr-3 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500"
                 placeholder="Username"/>
        </div>
        <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
            <fa-icon [icon]="faFingerprint"
                     [ngClass]="isFieldInvalid('password') ? 'text-red-400' : 'text-gray-400'"></fa-icon>
          </div>
          <input [type]="showPassword() ? 'text' : 'password'" id="password-mobile"
                 [(ngModel)]="password" name="password-mobile"
                 (blur)="onFieldBlur('password')"
                 [ngClass]="inputClass('password')"
                 class="w-full pl-10 pr-10 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500"
                 placeholder="Password"/>
          <button type="button" (click)="togglePasswordVisibility()"
                  class="absolute inset-y-0 right-0 flex items-center pr-3">
            <fa-icon [icon]="showPassword() ? faEyeSlash : faEye" class="text-gray-400" [ngClass]="isFieldInvalid('password') ? 'text-red-400' : 'text-gray-400'"></fa-icon>
          </button>
        </div>
        <button
          type="submit"
          [disabled]="authService.$$isSubmitting()"
          class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg px-5 py-2.5 text-center transition duration-200 ease-in-out"
          [ngClass]="{'animate-shake': authService.$$invalidSubmission()}">
          Login
        </button>
        @if (authService.$$invalidSubmission()) {
          <div class=" text-center text-red-800 p-2 text-sm bg-red-50 rounded-lg">{{ authService.$$errorMessage() }}</div>
        }
        <div class="text-center">
          <a disabled class=" text-gray-500">or continue with</a>
        </div>
        <div class="flex gap-2">
          <button
            (click)="handleMicrosoftSignIn()"
            type="button"
            [disabled]="authService.$$isSubmitting()"
            class="w-full text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center mb-2">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" viewBox="0 0 20 20">
              <g fill="none">
                <path d="M0 0h9.504v9.504H0z" fill="#F25022"></path>
                <path d="M10.496 0H20v9.504h-9.504z" fill="#7FBA00"></path>
                <path d="M0 10.496h9.504V20H0z" fill="#00A4EF"></path>
                <path d="M10.496 10.496H20V20h-9.504z" fill="#FFB900"></path>
              </g>
            </svg>
            {{ authService.$$isSubmitting() ? 'Loading...' : 'Microsoft' }}
          </button>
          <button
            (click)="handleGoogleSignIn()"
            disabled
            type="button"
            class="w-full text-gray-500 bg-white cursor-not-allowed border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center mb-2">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" viewBox="0 0 20 20" preserveAspectRatio="xMidYMid meet" focusable="false">
              <path
                d="M19.6 10.23c0-.82-.1-1.42-.25-2.05H10v3.72h5.5c-.15.96-.74 2.31-2.04 3.22v2.45h3.16c1.89-1.73 2.98-4.3 2.98-7.34z"
                fill="#9ca3af"></path>
              <path
                d="M13.46 15.13c-.83.59-1.96 1-3.46 1-2.64 0-4.88-1.74-5.68-4.15H1.07v2.52C2.72 17.75 6.09 20 10 20c2.7 0 4.96-.89 6.62-2.42l-3.16-2.45z"
                fill="#9ca3af"></path>
              <path
                d="M3.99 10c0-.69.12-1.35.32-1.97V5.51H1.07A9.973 9.973 0 000 10c0 1.61.39 3.14 1.07 4.49l3.24-2.52c-.2-.62-.32-1.28-.32-1.97z"
                fill="#9ca3af"></path>
              <path
                d="M10 3.88c1.88 0 3.13.81 3.85 1.48l2.84-2.76C14.96.99 12.7 0 10 0 6.09 0 2.72 2.25 1.07 5.51l3.24 2.52C5.12 5.62 7.36 3.88 10 3.88z"
                fill="#9ca3af"></path>
            </svg>
            Google
          </button>
        </div>
      </form>
    </div>

    <div class="absolute inset-0 overflow-hidden pointer-events-none">
      <div
        class="absolute w-[100vw] h-[100vw] md:w-[80vw] md:h-[80vw] border border-[#0575E6] rounded-full top-[-50vw] left-[-50vw] md:top-[-20vw] md:left-[-40vw]"></div>
      <div
        class="absolute w-[100vw] h-[100vw] md:w-[80vw] md:h-[80vw] border border-[#0575E6] rounded-full bottom-[-60vw] right-[-50vw] md:bottom-[-40vw] md:right-[-30vw]"></div>
    </div>
  </div>

  <div class="hidden md:flex md:w-2/5 w-full flex-col justify-center p-8 bg-white">
    <div class="w-full max-w-lg mx-auto">
      <h2 class="text-2xl md:text-2xl font-semibold text-gray-800 mb-2">{{ greeting() }}</h2>
      <p class="text-gray-600 mb-8">{{ greetingMessage() }}</p>
      <form (ngSubmit)="onUserLogin()" class="space-y-6">
        <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
            <fa-icon [icon]="faAt" [ngClass]="isFieldInvalid('username') ? 'text-red-400' : 'text-gray-400'"></fa-icon>
          </div>
          <input type="text" id="username-desktop"
                 [(ngModel)]="username" name="username-desktop"
                 (blur)="onFieldBlur('username')"
                 [ngClass]="inputClass('username')"
                 class="w-full pl-10 pr-3 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500"
                 placeholder="Username"/>
        </div>
        <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
            <fa-icon [icon]="faFingerprint"
                     [ngClass]="isFieldInvalid('password') ? 'text-red-400' : 'text-gray-400'"></fa-icon>
          </div>
          <input [type]="showPassword() ? 'text' : 'password'" id="password-desktop"
                 [(ngModel)]="password" name="password-desktop"
                 (blur)="onFieldBlur('password')"
                 [ngClass]="inputClass('password')"
                 class="w-full pl-10 pr-10 py-2 rounded-lg border focus:outline-none focus:ring-2 focus:ring-blue-500"
                 placeholder="Password"/>
          <button type="button" (click)="togglePasswordVisibility()"
                  class="absolute inset-y-0 right-0 flex items-center pr-3">
            <fa-icon [icon]="showPassword() ? faEyeSlash : faEye" class="text-gray-400"></fa-icon>
          </button>
        </div>
        <button
          type="submit"
          [disabled]="authService.$$isSubmitting()"
          class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg px-5 py-2.5 text-center transition duration-200 ease-in-out"
          [ngClass]="{'animate-shake': authService.$$invalidSubmission()}">
          Login
        </button>
        @if (authService.$$invalidSubmission()) {
          <div class=" text-center text-red-800 p-3 mb-4 text-sm bg-red-50 rounded-lg">{{ authService.$$errorMessage() }}</div>
        }
        <div class="text-center">
          <a disabled class=" text-gray-500">or continue with</a>
        </div>
        <div class="flex gap-2">
          <button
            (click)="handleMicrosoftSignIn()"
            type="button"
            [disabled]="authService.$$isSubmitting()"
            class="w-full text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center mb-2">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" viewBox="0 0 20 20">
              <g fill="none">
                <path d="M0 0h9.504v9.504H0z" fill="#F25022"></path>
                <path d="M10.496 0H20v9.504h-9.504z" fill="#7FBA00"></path>
                <path d="M0 10.496h9.504V20H0z" fill="#00A4EF"></path>
                <path d="M10.496 10.496H20V20h-9.504z" fill="#FFB900"></path>
              </g>
            </svg>
            {{ authService.$$isSubmitting() ? 'Loading...' : 'Microsoft' }}
          </button>
          <button
            (click)="handleGoogleSignIn()"
            disabled
            type="button"
            class="w-full text-gray-500 bg-white cursor-not-allowed border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center mb-2">
            <svg aria-hidden="true" class="w-5 h-5 mr-2" viewBox="0 0 20 20" preserveAspectRatio="xMidYMid meet" focusable="false">
              <path
                d="M19.6 10.23c0-.82-.1-1.42-.25-2.05H10v3.72h5.5c-.15.96-.74 2.31-2.04 3.22v2.45h3.16c1.89-1.73 2.98-4.3 2.98-7.34z"
                fill="#9ca3af"></path>
              <path
                d="M13.46 15.13c-.83.59-1.96 1-3.46 1-2.64 0-4.88-1.74-5.68-4.15H1.07v2.52C2.72 17.75 6.09 20 10 20c2.7 0 4.96-.89 6.62-2.42l-3.16-2.45z"
                fill="#9ca3af"></path>
              <path
                d="M3.99 10c0-.69.12-1.35.32-1.97V5.51H1.07A9.973 9.973 0 000 10c0 1.61.39 3.14 1.07 4.49l3.24-2.52c-.2-.62-.32-1.28-.32-1.97z"
                fill="#9ca3af"></path>
              <path
                d="M10 3.88c1.88 0 3.13.81 3.85 1.48l2.84-2.76C14.96.99 12.7 0 10 0 6.09 0 2.72 2.25 1.07 5.51l3.24 2.52C5.12 5.62 7.36 3.88 10 3.88z"
                fill="#9ca3af"></path>
            </svg>
            Google
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
